import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./assets/index.css";
import Navbar from "../../../Components/dashComponents/navbar";
import axios from "axios";
import moment from "moment";
import AppContext from "../../../AppContext";
import VerificationBar from "../../../Components/dashComponents/verification_bar";
import Pin from "../../../Components/dashComponents/pin";
import nigeria from "./assets/img/nigeria.svg";
import mtn from "./assets/img/mtn.jpg";
import glo from "./assets/img/glo.jpg";
import airtel from "./assets/img/airtel.jpg";
import nineMobile from "./assets/img/9mobile.jpg";
import smile from "./assets/img/smile.jpg";
import NumberFormat from "react-number-format";
import iziToast from "izitoast";
import * as ConstantVar from "../../../constants_var";
import { MdDelete } from "react-icons/md";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      phone_ok: false,
      amount: 0,
      amount_ok: false,
      phone_number: null,
      isSubmitting: false,
      isLoading: true,
      isVerifyingIuc: false,
      isEmpty: true,
      account: "invalid",
      mtn_subs: [],
      glo_subs: [],
      airtel_subs: [],
      ninemobile_subs: [],
      smile_subs: [],
      plan_options: [],
      key_code: "",
      beneficiary: false,
      checked: false,
      beneficiaries: [],
      beneficiary_type: "airtime",
    };
    this.setPhone = this.setPhone.bind(this);
    this.setAmount = this.setAmount.bind(this);
  }

  handleChange = (e) => {
    this.setState({ checked: !this.state.checked }, () => {
      console.log(this.state.checked, "this.state.checked");
    });
  };

  deleteBeneficiary = (beneficiary) => {
    axios
      .post("/user/remove_beneficiary", {
        token: localStorage.getItem("token"),
        beneficiary_type: beneficiary.type,
        beneficiary_number: beneficiary.number,
        // pin: pin,
      })
      .then((response) => {
        console.log(response, "removeBeneficiary");
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    // get beneficiaries
    axios
      .post(ConstantVar.API_BASE_URL + "/user/beneficiaries", {
        token: localStorage.getItem("token"),
        limit: 7,
      })
      .then((response) => {
        console.log(response, "dashboard beneficiaries");
        if (response.data.success === "true") {
          self.setState({
            beneficiaries: response.data.data.filter(item => item.type === "airtime"),
            isLoading: false,
          });
        }
        console.log(this.state.beneficiaries.number, "empy");
      });

    //get subscriptions
    let self = this;

    //get mtn first
    axios
      .get(ConstantVar.API_BASE_URL + "/data/subscriptions?provider=mtn")
      .then((response) => {
        if (response.data.success === "true") {
          self.setState({
            mtn_subs: response.data.data,
            plan_options: response.data.data,
            isLoading: false,
          });
        }

        //get glo
        axios
          .get(ConstantVar.API_BASE_URL + "/data/subscriptions?provider=glo")
          .then((response) => {
            if (response.data.success === "true") {
              self.setState({
                glo_subs: response.data.data,
                isLoading: false,
              });
            }

            //get airtel
            axios
              .get(
                ConstantVar.API_BASE_URL + "/data/subscriptions?provider=airtel"
              )
              .then((response) => {
                if (response.data.success === "true") {
                  self.setState({
                    airtel_subs: response.data.data,
                    isLoading: false,
                  });
                }

                //get 9mobile
                axios
                  .get(
                    ConstantVar.API_BASE_URL +
                      "/data/subscriptions?provider=9mobile"
                  )
                  .then((response) => {
                    if (response.data.success === "true") {
                      self.setState({
                        ninemobile_subs: response.data.data,
                        isLoading: false,
                      });
                    }

                    //get smile
                    axios
                      .get(
                        ConstantVar.API_BASE_URL +
                          "/data/subscriptions?provider=smile-direct"
                      )
                      .then((response) => {
                        if (response.data.success === "true") {
                          self.setState({
                            smile_subs: response.data.data,
                            isLoading: false,
                          });
                        }
                      });
                  });
              });
          });
      });
  }

  setPhone(e) {
    let phoneNo = e.target.value.replace(/\s+/g, "");

    this.setState({ ...this.state, checked: false });

    //get the first 4 characters to determine what network
    let first4 = phoneNo.substring(0, 4);

    let mtn = [
        "0703",
        "0704",
        "0706",
        "0707",
        "0803",
        "0806",
        "0810",
        "0813",
        "0814",
        "0816",
        "0903",
        "0906",
        "0913",
        "0916",
        "07025",
        "07026",
      ],
      glo = ["0705", "0805", "0807", "0811", "0815", "0905", "0915"],
      airtel = [
        "0701",
        "0708",
        "0802",
        "0808",
        "0812",
        "0901",
        "0902",
        "0904",
        "0907",
      ],
      smile = ["0702"],
      nineMob = ["0809", "0817", "0818", "0909", "0908"];

    if (mtn.includes(first4))
      this.setState({
        selected: "mtn",
        plan_options: this.state.mtn_subs,
      });
    else if (glo.includes(first4))
      this.setState({
        selected: "glo",
        plan_options: this.state.glo_subs,
      });
    else if (airtel.includes(first4))
      this.setState({
        selected: "airtel",
        plan_options: this.state.airtel_subs,
      });
    else if (nineMob.includes(first4))
      this.setState({
        selected: "9mobile",
        plan_options: this.state.ninemobile_subs,
      });
    else if (smile.includes(first4))
      this.setState({
        selected: "smile-direct",
        plan_options: this.state.smile_subs,
      });
    else this.setState({ selected: "null" });
    let covered = this.state.beneficiaries.filter(
      (item) => item.number === phoneNo
    );
    // else this.setState({ selected: "null" });

    //   this.setState({
    //     phone_number: phoneNo,
    //     phone_ok: phoneNo.length === 11,
    //   });
    // }
    this.setState(
      {
        phone_number: phoneNo,
        phone_ok: phoneNo.length === 11,
      },
      () => {
        if (covered[0]?.number === phoneNo) {
          console.log("covered");
          this.setState({ checked: true });
        }
      }
    );
  }
  
  setAmount(e) {
    let amount = e.target.value.toString();

    //strip amount of the commas and all
    if (amount !== "") {
      amount = parseFloat(amount.replace(/,/g, ""));
    }

    this.setState({
      amount: amount,
      amount_ok: amount >= 50,
    });
  }
  render() {
    let options = this.state.plan_options,
      plans = [],
      placeholder = "";
      options.map((option, index) => {
        placeholder = index === 0 ? option.name : placeholder;
        plans.push({
          key: option.variation_code,
          value: option.variation_amount,
          text: option.name,
        });
      });

    // let dataPayload = {
    //   provider: this.state.selected,
    //   phone_no: this.state.phone_number,
    //   sub_plan: this.state.key_code,
    // };

    return (
      <div className="bckgrd-color">
        <div>
          <Navbar />
          <div className="container">
            <VerificationBar
              email_verified_at={this.context.user.email_verified_at}
            />
          </div>
        </div>
        <div className="bckgrd-color">
          <div className="container dashbord-con">
            {/*<ActionBar/>*/}
            <div className="row">
              <div className="col-sm-3" />
              <div className="col-sm-6">
                <div className="card-bill card">
                  <div className="card-body">
                    <h4
                      style={{ padding: 30 }}
                      className="text-center font-weight-bold"
                    >
                      Buy Data
                    </h4>
                    <div style={{ padding: 20 }}>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div
                              className="input-group-text"
                              style={{
                                width: 50,
                                backgroundColor: "white",
                                borderColor: this.state.phoneFocus
                                  ? "#495057"
                                  : "#eee",
                              }}
                            >
                              <img
                                src={nigeria}
                                style={{ width: "100%", marginLeft: 8 }}
                              />
                            </div>
                          </div>
                          <input
                            required
                            onClick={() => {
                              this.setState({ phoneFocus: true });
                            }}
                            onBlur={() => {
                              this.setState({ phoneFocus: false });
                            }}
                            type="number"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.setPhone}
                            style={{ borderLeft: 0 }}
                            className="form-control login-input phone-input"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="081xxxxxxxx"
                          />
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: 15 }}>
                        <div className="col-sm-3 col-3">
                          <div
                            className={
                              this.state.selected === "mtn" ? "selected" : ""
                            }
                          >
                            <img className="airtime-card" src={mtn} />
                          </div>
                        </div>
                        <div className="col-sm-3 col-3">
                          <div
                            className={
                              this.state.selected === "glo" ? "selected" : ""
                            }
                          >
                            <img className="airtime-card" src={glo} />
                          </div>
                        </div>
                        <div className="col-sm-3 col-3">
                          <div
                            className={
                              this.state.selected === "airtel" ? "selected" : ""
                            }
                          >
                            <img className="airtime-card" src={airtel} />
                          </div>
                        </div>
                        <div className="col-sm-3 col-3">
                          <div
                            className={
                              this.state.selected === "9mobile"
                                ? "selected"
                                : ""
                            }
                          >
                            <img className="airtime-card" src={nineMobile} />
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginBottom: 15 }}>
                        <div className="col-sm-3 col-3">
                          <div
                            className={
                              this.state.selected === "smile-direct"
                                ? "selected"
                                : ""
                            }
                          >
                            <img className="airtime-card" src={smile} />
                          </div>
                        </div>
                      </div>
                      <div style={{ marginBottom: 20 }}>
                        <label>Select data plan</label>
                        <select
                          onChange={(e) =>
                            this.setState({ key_code: e.target.value })
                          }
                          className="custom-select form-control form-control-pad"
                        >
                          <option hidden className="text-muted" selected>
                            Select data plan
                          </option>
                          {plans.map((plan, index) => {
                            return (
                              <option key={index} value={plan.key}>
                                {plan.text}
                              </option>
                            );
                          })}
                        </select>
                        <NumberFormat
                          style={{
                            borderBottom: "1px dashed #eee",
                            display: "inline-block",
                            marginTop: 10,
                          }}
                          value={truncate(this.context.user.available_bal, 2)}
                          className="text-blue"
                          displayType={"text"}
                          prefix={"Balance: NGN "}
                          decimalScale={2}
                          thousandSeparator={true}
                        />
                      </div>
                      {!this.state.checked && (
                        <div>
                          <input
                            type="checkbox"
                            onChange={this.handleChange}
                            defaultChecked={this.state.checked}
                            // value={this.state.checked}
                            style={{ marginRight: 10 }}
                            name="checked"

                          />
                          <label>Save as beneficiary </label>
                        </div>
                      )}

                      <button
                        data-toggle="modal"
                        data-target="#pinModal"
                        disabled={!this.state.phone_ok}
                        type="submit"
                        className="col-sm-12 pl-0 btn signup-btn text-white"
                      >
                        Buy data now
                      </button>
                    </div>
                  </div>
                  {/* <Pin endpoint="/user/pay_data" data={dataPayload} /> */}
                </div>
              </div>
              <div className="col-sm-3" />
            </div>
          </div>
        </div>
        {
        this.state.beneficiaries.length>0 ? (
          <div className="col-sm-8 container dashbord-con">
            <p className="card-inner-pad-tran-text text-muted">
              Saved Beneficiaries
            </p>
            <table className="table  card-bill ">
              <tbody>
                {this.state.beneficiaries.map((beneficiary, index) =>
                 
                    <tr className="table-hover" key={index}>
                      <td
                        className="table-dash-img text-center"
                        style={{ width: "17%" }}
                      >
                        <div className="phone-bckg">
                          <i className="fas fa-phone phone-color" />
                        </div>
                      </td>
                      <td>
                        <div style={{ marginTop: 1 }}>
                          <p>{beneficiary.number}</p>
                          <br />
                          <p className="table-dash-details2">
                            {beneficiary.name}{" "}
                          </p>
                        </div>
                      </td>
                      <td>
                        <button
                          data-toggle="modal"
                          type="submit"
                          onClick={() => this.setState({ beneficiary: true })}
                          data-target="#pinModal"
                          style={{ color: "red", border: "none" }}
                        >
                          <MdDelete />
                        </button>

                        <Pin
                          endpoint={
                            !this.state.beneficiary
                              ? "/user/pay_data"
                              : "/user/remove_beneficiary"
                          }
                          data={
                            this.state.beneficiary
                              ? {
                                  beneficiary_type: beneficiary.type,
                                  beneficiary_number: beneficiary.number,
                                }
                              : {
                                  provider: this.state.selected,
                                  phone_no: this.state.phone_number,
                                  sub_plan: this.state.key_code,
                                  save_as_beneficiary: `${this.state.checked}`,
                                  use_loan_wallet: "false",
                                }
                          }
                        />
                      </td>
                    </tr>
                 
                )}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}
Dashboard.contextType = AppContext;
export default Dashboard;

function truncate(num, places) {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}