import React from "react";
import logo from "./logo.png";
import googleButton from "../homeAssets/googleButton.png";
import appleButton from "../homeAssets/appleButton.png";
import { Link } from "react-router-dom";
import axios from "axios";
import * as ConstantVar from "../constants_var";

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      socials: {},
    };
  }

  componentDidMount() {
    axios
      .get(ConstantVar.API_BASE_URL + `/app/socials`)

      .then((res) => {
        console.log(res);
        this.setState({ socials: res.data.data });
        console.log(this.state.socials);
      });
  }

  render() {
    return (
      <div>
        <section className="footer-section">
          <div className="first-footer-div">
            <div className="">
              <div className="container">
                <div className="row">
                  <div className="col-sm-9">
                    <ul className="list-unstyled footer-li">
                      <li className="list-group-item">
                        <Link to="/about">About</Link>
                      </li>
                      <li className="list-group-item">
                        <Link to="/faqs">FAQs</Link>
                      </li>
                      <li className="list-group-item">
                        <Link to="">Contact us</Link>
                      </li>
                      <li className="list-group-item">
                        <Link to="/terms">Terms & Condition</Link>
                      </li>
                      <li className="list-group-item">
                        <Link to="/policies">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-3">
                    <ul className="list-unstyled d-inline-flex">
                      <li className="list-group-item">
                        <div className="icon-circle">
                          <a href={this.state.socials.facebook}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAAAqUlEQVRIie3SsQ3CMBBA0buUdFTUzEDPEEwAU9EyC2tEiI4SapCiT0PDSeDjjCOE7peO42cnFsl+OUCBNbAHLsAAnIEDsGgJ73jdshW6eoO64S5gbwLv1AeczAm3wHQM+GrgWWQdDcCYoU5V7VixyD9+KoK6YHtlS8+BuWvDHtiz0KObiExUdShNrP7UpqMHbQH33onVt1pVP15D5PsnTjjhhBP+YzgbrTs8kL3ZJFJksQAAAABJRU5ErkJggg==" />
                          </a>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="icon-circle">
                          <a href={this.state.socials.twitter}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAABxklEQVRIie2WPWsUURRAz41GBBG/SGdpYURstTAGRRBE7ARRyC9Q8C8ItiJ+dIKFYiEWirAEU5gilrKFBCy0cHHBRgVRNMZkj8VuYDKZt5kZp9Kcbt7cd8+7d2beG9jgXyeaSKJuBc4AhwCB10ArIhZycVsiYrEowQF1Z0XpcfWDa+mok+pu9aI6o25PJbmvziUD1sYfUX8WSFdYVJfUnnpBLe6wOj+Y8ErdW0LcHiJdoac+Ua+rl1OJ3mYmfFLPD5GOl5BmeaxuSiV7WjBhTj2db5N6toL0rjqSnT+yWs29gvUcBVpAR72pnlPHgeVUNwqYiYhe8q46qj6o2MIyTOZd+YrbQA/4XKGaMnzMD2zOXXeAqYal34H3+cF8xbcblgLMFu1Wq8QR8Ry41rB4unSkOqV2G3ipvpjYBfOtxv73NgG8AX7VLXPArYj4VjpavdJAtV11V6VlqqHe+QvpsnqianuyCzipPrP42BvG1drSjHy/+rKC9Iap46+EbJt6Sn2k/i4pXDB17K0jm1Bb6jv7B3gVXqgHa1U5kO9QL9n/EViPr+pD9VgdV/JZqGPAYWAfsAcYBX4AXWAeaEfEUh3pBv8HfwCk8tBPONBILwAAAABJRU5ErkJggg==" />
                          </a>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="icon-circle">
                          <a href={this.state.socials.email}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAACj0lEQVRIie2WTYiNYRTHf8+Mr4iRGRJRhEwhC0xjIx+FhVBslCVJ2Fgo+SqSjwVbW9lZIKEsEPIZyUqRFJNJZpRJmmH8LO69zZnXfd/u+FrNv956nnPO//zPed/nOffCIP4TUq2BajOwGlgBTAPGAw3Ax/LzFLgBXE4pdf5xZWqLetXa0aWeUpuK8uZ2rNYBB4H9mbgO4D7QDnwGmoDpQAswNMS1AZtSSrcH0mWdej7TyU11qVqfwxmt7lLbAqdbXToQ4RMZ8pYBcBvV64H/SZ1eC3FJIP1Q19VccV+OEerDkOd8LaS7gXAs42tVz6qP1BvqDrVeXaXuKT8by7Ez1W+hgblFoguDaKfaEHzb1F5/xVn1TNhfCZxzwX6gSPhQCDwd7HPUniqiFXTkCG8M9jtRqy6jvSysr4f1TvpflVtAK7AGeAeMy+nlQVhPKer4dahwUrC/DPYedXLwrc90Hzsebun7qn4t6nhChUNpDFar9k1KqS3sb+V2At/LuQB6ioQrE0qgt4odoDvDye4jmoJGe5Hwh2BvDPb3YT1NHRX28zI5YpGLwvppkXB8hc1hHU/kKOCIpfvbABzP5JgKoCZge7DnDxH1cDgkR4N9cTgkFXTZNyC6M76L6rWwf6YOKRJuDcFt6sjgO2l1dKrz1S85/vfq7FzRyuux/4w9mPFtVV8F/xN1QSj6nn3TrcPSRJtYKBoElofEveraKjFj7H/Aom+YOromsSrk00G8R91t6bD8W1g6sRcy3+q5ulkd+zc0iv761AMHgH30v3Y/gOfAW0rTrQ4YC8wC9qaULv6Nwio/lZf89TpVw4Za8+bfrTJSSo+BteoMYCWwnFJ3TeXnC/AJeAG8/p3mBvFP8ROPkI290wZgtQAAAABJRU5ErkJggg==" />
                          </a>
                        </div>
                      </li>
                      <li className="list-group-item">
                        <div className="icon-circle">
                          <a href={this.state.socials.instagram}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABsElEQVRYhe2WzU7CQBSFkT9XgFtxgz6DuBHZ+ACojwHoWoGn8Ad9ApHXMFE0QeNCQffIAwjuIJ8LbsPYtKUzhOiiJ2na3Lnn3DPTmduGQgEC/HcAeaAJ9IExszEGPoWzM0/hGHDlo+AsXAIxEwNW8QFwDGSAsA9eGFgHKsBQNOq6xfNK8U1t91OdrGIip0NsCunEsPAZUJbnqmg1dAT6Qso4jC0Dh8CDzG4ItIAyEJecW+BUnjdEq6djYCSkiC2+Brx4bLhnIG3jRGRspGMAAIeZW8XfgAKQkGsP6MrYk7USXnomBo4k3AFSDpyUYqK0CAOPEi548PYlp7UIAwMJJzx4Scn58mtgZmNRdXzkLGnoaRvoyH3XI8cae9U18gsur6As4a7LJlwBPiSnOEvPxECcyTkHeJcNl5TrQCnexvbxMTHg1ojSigkntIFVG8eoEXm14jhQAu7lZAyAO6Bon7nkG7XiGyFVfJPctWqida1D2hHSEMjOUXwL+BatbV1yXTFRZfKT4eeHJCLLXlOKX5i4jyom5sE5ENU2oBjJAQ2gx/R0eGEkuQ3tZQ8Q4C/wA/lL1GfXg1+GAAAAAElFTkSuQmCC" />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="second-footer-div">
            <div className="">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="">
                      <p className="text-muted">
                        Hometown is a product of Hometown Fintech Limited (RC
                        1662509), a Fintech platform which offer loans,
                        Investment and other services. Investment management,
                        Loans and other services are provided by Hometown
                        Limited, which is registered with the Corporate Affairs
                        Commission and Central Bank of Nigeria. Payments on
                        Hometown are made via Flutterwave and Monnify; a PCI DSS
                        certified payment platform with bank level security to
                        ensure your transactions and financial information are
                        kept safe at all times.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="third-footer-div">
            <div className="">
              <div className="container">
                <div className="row pt-4">
                  <div className="col-sm-6">
                    <div className="text-center float-left">
                      <p className="head-para">
                        &copy; {new Date().getFullYear()} {" "} Hometown Fintech
                        Limited. All rights reserved.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 pr-0">
                    <div className="">
                      <div className="row">
                        <div className="col-sm-3" />
                        <div className="col-sm-3" />
                        <div
                          className="col-5 col-sm-3"
                          style={{ marginRight: "inherit" }}
                        >
                          <a className="">
                            <img
                              onClick={() => {
                                window.location.href =
                                  "https://play.google.com/store/apps/details?id=com.hometown.app&hl=en";
                              }}
                              className="google-btn"
                              src={googleButton}
                              alt="googleButton"
                            />
                          </a>
                        </div>

                        <div
                          className="col-5 col-sm-3"
                          style={{ marginRight: "inherit" }}
                        >
                          <a className="">
                            <img
                              onClick={() => {
                                window.location.href =
                                  "https://apps.apple.com/us/app/hometown-app/id1525335534?app=itunes&ign-mpt=uo%3D4";
                              }}
                              className="google-btn"
                              src={appleButton}
                              alt="appleButton"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default Footer;
