import React from 'react';
import Logo from './logo.png'
import './index.css'
import AppContext from '../AppContext'
import { Link } from 'react-router-dom'

class Navbar extends React.Component{
    constructor(props){
        super(props);
        this.state = {

        };
        this.openNav = this.openNav.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    //for sidebar on mobile
    openNav() {
        document.getElementById("mySidenav").style.width = "300px";
        setTimeout(function(){
            document.getElementById("back-drop").style.display = "block";
        },50)
    }

    /* Set the width of the side navigation to 0 */
    closeNav() {
        document.getElementById("mySidenav").style.width = "0";
        setTimeout(function(){
            document.getElementById("back-drop").style.display = "none";
        },200)
    }

    render(){
        return(
            <div>
                <nav className="navbar fixed-top bg-white navbar-expand-lg">
                    <div className="container">
                        <div className="row w-100 align-items-center">
                            <div className="col-sm-2">
                                <div className="row">
                                    <div className="col-4">
                                        <button
                                            onClick={this.openNav}
                                            className="navbar-toggler" type="button"
                                                aria-expanded="false">
                                            <i className="header-icon fas fa-bars"/>
                                        </button>
                                        <div className="back-drop" id="back-drop" onClick={this.closeNav}/>
                                        <div id="mySidenav" className="sidenav desktop-remove">
                                            <a className="closebtn text-white" onClick={this.closeNav}>&times;</a>
                                           
                                            <ul className="list-unstyled navbar-sidenav ">
                                        <li className="list-group-item text-white">
                                            <Link to="/about">
                                            About
                                            </Link>
                                            </li>
                                        <li className="list-group-item text-white">
                                            <Link to="/faqs">
                                            FAQs
                                            </Link>
                                        </li>
                                        <li className="list-group-item text-white">
                                            <Link to="">
                                            Contact us
                                            </Link>
                                        </li>
                                        <li className="list-group-item text-white">
                                            <Link to="/terms">
                                            Terms & Condition
                                            </Link>
                                        </li>
                                        <li className="list-group-item text-white">
                                            <Link to="/policies">
                                            Privacy Policy
                                            </Link>
                                        </li>
                                    </ul>
                            <div className="row">
                                <div className="col-12" style={{padding:'0 2.25rem'}}>
                                    <Link to="/login/account" className="btn btn-outline-white" style={{color: 'white', border:'3px solid',padding: '10px 34px'}}>Log in here</Link>
                                </div>
                                <div className="col-12" style={{padding:'1.25rem 2.25rem'}}>
                                    <Link to="/signup/details" className="btn btn-success text-white" style={{padding: '13px 22px'}}>Sign Up for free</Link>
                                </div>
                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8 col-sm-12">
                                        <Link className="" to="/">
                                            <img className="w-100 pt-2 logo"  src={Logo} alt="logo"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 d-none d-lg-block mt-4">
                                <ul className='d-flex justify-content-between '>
                                        <Link to="/about">
                                    <li className="mr-3 text-success">
                                            About
                                    </li>
                                        </Link>
                                        <Link to="">
                                    <li className="mr-3 text-success">
                                            Contact us
                                    </li>
                                        </Link>
                                        <Link to="/faqs">
                                    <li className="mr-3 text-success">
                                            FAQs
                                    </li>
                                        </Link>
                                    
                                </ul>

                            </div>

                            <div className="col-sm-6">
                                <div className="row float-right collapse navbar-collapse" id="navbarcontent">
                                    <Link to="/login/account" className="btn btn-outline-primary border-success text-success mr-3">Log in here</Link>
                                    <Link to="/signup/details" className="btn bg-success text-white">Sign Up for free</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

            </div>
        )
    }
}
Navbar.contextType = AppContext;
export default Navbar;